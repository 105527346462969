<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >

        <b-card
          :title="$t('Create New Checkin')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-input-group>
                  <b-form-input
                    id="filter-input"
                    v-model="data.chassis_number "
                    :formatter="formatter"
                    type="search"
                    :placeholder="$t('Type to Search')"
                    @keyup.enter="onEnter"
                  />
                </b-input-group>
                <v-select
                  v-model="data.chassis_number"
                  :options="Checkins"
                  :reduce="Checkins => Checkins.chassis_number"
                  label="chassis_number"
                />
                <small
                  v-if="errors.chassis_number"
                  class="text-danger"
                >{{ errors.chassis_number[0] }}</small>
                <label>{{ $t('Chassis_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Cr') }}</label>
                <b-form-select
                  v-model="data.cr"
                  :options="options"
                />
                <small
                  v-if="errors.cr"
                  class="text-danger"
                >{{ errors.cr[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
            >
              <b-button
                v-for="(color,index) in colors"
                :key="index"
                class="sm"
                :variant="color"
                @click="changeColorTo(index)"
              >{{ index }}
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('Color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('Color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Created_year') }}</label>
                <cleave
                  v-model="data.created_year"
                  class="form-control"
                  :class="errors.created_year ?'is-invalid':null"
                  :options="{date: true, datePattern: ['Y']}"
                  :placeholder="$t('Created_year')"
                />
                <small
                  v-if="errors.created_year"
                  class="text-danger"
                >{{ errors.created_year[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Normal/Assa') }}</label>
                <b-form-select
                  v-model="data.type"
                  :options="typeoptions"
                />
                <small
                  v-if="errors.type"
                  class="text-danger"
                >{{ errors.type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Check_in_date') }}</label>
                <b-form-datepicker
                  v-model="data.check_in_date"
                  placeholder="Check_in_date"
                  :state="errors.check_in_date ?false:null"
                />
                <small
                  v-if="errors.check_in_date"
                  class="text-danger"
                >{{ errors.check_in_date[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.technical_detection"
                  class="mt-2"
                  :state="errors.technical_detection ?false:null"
                  :placeholder="$t('technical_detection')"
                />
                <small
                  v-if="errors.technical_detection"
                  class="text-danger"
                >{{ errors.technical_detection[0] }}</small>
                <label>{{ $t('technical_detection') }}</label>
              </div>
            </b-col>

          </b-row>
        </b-card>
        <b-card :title="$t('Cars')">
          <b-row
            v-if="data.car"
          >
            <b-col
              v-for="el in ['no','manufacturer', 'model', 'wight', 'passenger', 'axle', 'cylinder', 'motor_type', 'motor_power', 'motor_size', 'car_type']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="$t(SelectedCar(data.car)[el])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Ibrokers')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.ibroker"
                :options="Ibrokers"
                :reduce="Ibrokers => Ibrokers.id"
                label="name"
              />
              <small
                v-if="errors.ibroker"
                class="text-danger"
              >{{ errors.ibroker[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Ebrokers')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.ebroker"
                :options="Ebrokers"
                :reduce="Ebrokers => Ebrokers.id"
                label="name"
              />
              <small
                v-if="errors.ebroker"
                class="text-danger"
              >{{ errors.ebroker[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            v-if="true"
            cols="12"
          >
            <b-card :title="$t('Owners')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.owner"
                :options="Owners"
                :reduce="Owners => Owners.id"
                label="name"
              />
              <small
                v-if="errors.owner"
                class="text-danger"
              >{{ errors.owner[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Cars')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.car"
                :options="Cars"
                :reduce="Cars => Cars.id"
                label="name"
              >
                <template #option="{ manufacturer,model }">
                  <span> {{ manufacturer }} ({{ model }})</span>
                </template>
              </v-select>
              <small
                v-if="errors.car"
                class="text-danger"
              >{{ errors.car[0] }}</small>
            </b-card>
          </b-col>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="resetData"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'Container', text: 'كونتينر' },
        { value: 'ROR', text: 'رورو' },
        { value: 'Land_shipping', text: 'شحن بري' },
        { value: 'Other', text: 'غير ذلك' },
      ],
      typeoptions: [
        { value: 'Normal', text: 'عادي' },
        { value: 'Assa', text: 'قصة' },
      ],
      data: {
        created_year: '',
        car: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
        color: '',
      },
      colors: {
        أخضر: 'success',
        برتقالي: 'warning',
        أحمر: 'danger',
        أزرق: 'info',
        فضي: 'secondary',
        أسود: 'dark',
        أحمر_فاتح: 'danger',
        ذهبي: 'warning',
        أزرق_سماوي: 'info',
        أبيض: 'light',
        أصفر: 'warning',
        زيتي: 'success',
        جردوني: 'light',
        خمري: 'danger',
        أزرق_فاتح: 'secondary',
        كموني_فاتح: 'success',
        بنفسجي: 'light',
        أصفر_فاتح: 'warning',
        كحلي: 'light',
        بني: 'dark',
        كرزي: 'danger',
      },
      created_year: {
        N: '1992',
        P: '1993',
        R: '1994',
        S: '1995',
        T: '1996',
        V: '1997',
        W: '1998',
        X: '1999',
        Y: '2000',
        1: '2001',
        2: '2002',
        3: '2003',
        4: '2004',
        5: '2005',
        6: '2006',
        7: '2007',
        8: '2008',
        9: '2009',
        A: '2010',
        B: '2011',
        C: '2012',
        D: '2013',
        E: '2014',
        F: '2015',
        G: '2016',
        H: '2017',
        J: '2018',
        K: '2019',
        L: '2020',
        M: '2021',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    Ibrokers() { return this.$store.getters['ibroker/GetElements'] },

    Ebrokers() { return this.$store.getters['ebroker/GetElements'] },

    Owners() { return this.$store.getters['owner/GetElements'] },

    Cars() { return this.$store.getters['car/GetElements'] },
    Checkins() {
      return this.$store.getters['checkin/GetElements']
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.checkin': function () {
      this.data.chassis_number = this.data.checkin
    },
    // eslint-disable-next-line func-names
    'data.chassis_number': function (newEl) {
      const year = this.created_year[newEl.slice(9, 10)]
      if (year) {
        this.data.created_year = year
      }
    },
  },
  created() {
    this.$store.dispatch('ibroker/GetElements')

    this.$store.dispatch('ebroker/GetElements')

    this.$store.dispatch('owner/getOwners')

    this.$store.dispatch('car/GetElements')
    this.$store.dispatch('checkin/GetElementsByChassis')
  },
  methods: {
    changeColorTo(val) {
      this.data.color = val
    },
    onEnter() {
      this.chassis_number = this.checkin
      this.currentPage = 1
      this.getElementdataByChassis()
    },
    getElementdataByChassis() {
      this.$store.dispatch('checkin/GetElementsByChassis', `page=1&search=${this.data.chassis_number}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.chassis_number = response.data.checkins[0].chassis_number
      })
    },
    formatter(value) {
      return value.toUpperCase()
    },
    SelectedCar(val) {
      return this.Cars.find(el => el.id === val)
    },
    resetData() {
      this.data = {
        chassis_number: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
      }
      this.errors = []
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      this.$store.dispatch('checkin/Create', this.data).then(() => {
        this.resetData()
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
